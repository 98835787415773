import {
  timeManager,
  audioManager,
  CustomEvents,
  corePhasesManager,
  playersManager,
  fpsManager,
  gsap,
  game,
  minigameConfig,
  modes
} from '@powerplay/core-minigames'
import {
  type DisciplinePhaseManager,
  AudioNames,
  DisciplinePhases
} from '../types'
import { StartPhaseManager } from './StartPhase/StartPhase'
import { FinishPhaseManager } from './FinishPhase/FinishPhase'
import {
  player,
  playerAnimationManager
} from '../entities/player'
import { endManager } from '../EndManager'
import { trainingTasks } from '../modes/training'
import { RunUpPhase } from './RunUpPhase/RunUpPhase'
import { RunningPhase } from './RunningPhase/RunningPhase'
import { opponent } from '../entities/Opponent/Opponent'
import { audioHelper } from '../audioHelper/AudioHelper'
import { trainingResultsState } from '@/stores'
import { stateManager } from '../StateManager'
import { waitingState } from '@powerplay/core-minigames-ui'

/**
 * Trieda pre spravu faz
 */
export class DisciplinePhasesManager {

  /** aktualna faza */
  public actualPhase = 0

  /** pokus */
  public attempt = 1

  /** pole vytvorenych faze managerov */
  private phaseManagers: DisciplinePhaseManager[] = []

  /** distance between start and end */
  private distanceToEnd = 0

  /** tween na nastartovanie fazoveho managera */
  private startDisciplineTween!: gsap.core.Tween

  /** ci bola hra ukoncena predcasne */
  public prematureEnded = false

  /** Ci bol hrac diskvalifikovany alebo nie */
  public wasDsq = false

  /**
   * Vytvorenie a nastavenie veci
   */
  public create(): void {

    this.createAllPhases()

  }

  /**
   * Vytvorenie menegerov faz
   */
  public createAllPhases(): void {

    this.phaseManagers[DisciplinePhases.start] = new StartPhaseManager(() => {

      player.launchStartAnimation(false)
      this.startDisciplinePhase(DisciplinePhases.runUp)

    })

    this.phaseManagers[DisciplinePhases.runUp] = new RunUpPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.running)

    })

    this.phaseManagers[DisciplinePhases.running] = new RunningPhase(() => {

      this.startDisciplinePhase(DisciplinePhases.finish)

    })

    this.phaseManagers[DisciplinePhases.finish] = new FinishPhaseManager(() => {

      this.actualPhase++
      console.log('dispatch end')
      if (DisciplinePhases[this.actualPhase]) {

        console.log('dispatch end')
        window.dispatchEvent(new CustomEvent(CustomEvents.finishDisciplinePhase))

        waitingState().isWaiting = true

      }

    })

    // TODO: toto este budeme musiet nejako vymysliet, kam s tym
    player.setCollisionEndCallback(() => {

      if (this.actualPhase === DisciplinePhases.running) {

        /*
         * console.log('bol pad...')
         * const finish = this.phaseManagers[DisciplinePhases.finish] as FinishPhaseManager
         */

        // this.phaseManagers[DisciplinePhases.running].finishPhase()

      }

    })

  }

  /**
   * Zistenie, ci jedna z faza je aktualna faza
   * @param phase - Pole faz na skontrolovanie
   * @returns True, ak je jedna z faz aktualna
   */
  public oneOfPhaseIsActual(phases: DisciplinePhases[]): boolean {

    return phases.includes(this.actualPhase)

  }

  public getActualPhase(): DisciplinePhases {

    return this.actualPhase

  }

  /**
   * Vratenie konkretneho fazoveho menezera
   * @param phase - Faza
   * @returns Fazovy menezer
   */
  public getDisciplinePhaseManager(phase: DisciplinePhases): DisciplinePhaseManager {

    return this.phaseManagers[phase]

  }

  /**
   * Spustenie fazy
   * @param phase - Cislo fazy
   */
  public startDisciplinePhase(phase: DisciplinePhases): void {

    this.actualPhase = phase
    this.phaseManagers[phase].startPhase()

  }

  /**
   * Update aktualnej fazy kazdy frame
   */
  public update(): void {

    this.phaseManagers[this.actualPhase]?.update()

  }

  /**
   * resetovanie hry
   */
  public resetAttempt(resetAttempts = false): void {

    if (resetAttempts) {

      this.attempt = -1

    }
    if (this.attempt >= 2 && !modes.isTutorial()) {

      this.wasDsq = true
      playersManager.setPlayerResults(minigameConfig.dsqValue)
      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)
      return

    }

    this.attempt += 1

    console.log('reseting')
    player.hillLinesManager.reset()
    player.reset()
    opponent.reset()

    this.actualPhase = 0
    this.distanceToEnd = 0
    timeManager.reset()
    playerAnimationManager.reset()
    audioHelper.reset()

    this.startDisciplinePhase(DisciplinePhases.start)

  }

  /**
   * rekurzivne ukoncime vsetky fazy
   */
  public disciplinePrematureEnd = async (): Promise<void> => {

    audioManager.stopAllAudio()
    audioManager.play(AudioNames.audienceNoise, undefined, undefined, 1)
    this.actualPhase = DisciplinePhases.end

    corePhasesManager.disciplineActualAttempt = corePhasesManager.disciplineAttemptsCount
    playersManager.setStandings()
    console.log('STANDINGS', playersManager.getStandings())

    fpsManager.pauseCounting()

    // pri treningu musime dokoncit udaje
    trainingTasks.saveLastTasksValues()

    // posleme udaje
    endManager.sendLogEnd()
    endManager.sendSaveResult()

    // reset states
    stateManager.resetPinia()

    waitingState().isWaiting = true
    trainingResultsState().isDisabledPlayAgain = true

    // stopneme vsetky animacne callbacky
    player.animationsManager.removeCallbacksFromAllAnimations()
    opponent.removeCallbacksFromAllAnimations()

  }

  /**
   * Nastartovanie disciplinoveho fazoveho managera
   */
  public setStartPhase = (): void => {


    // musime tu dat mensi delay, lebo mozeme skipovat este nejake fazy predtym
    this.startDisciplineTween = gsap.to({}, {
      duration: 0, // modes.isTutorial() ? 0 : 0.2,
      onComplete: () => {

        this.startDisciplinePhase(DisciplinePhases.start)

      }
    })

  }

  /**
   * Reset
   */
  public reset(): void {

    this.actualPhase = 0
    this.prematureEnded = false
    this.wasDsq = false

  }

}

export const disciplinePhasesManager = new DisciplinePhasesManager()
