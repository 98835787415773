import {
  minigameConfig,
  modes,
  timeManager,
  gsap,
  playersManager,
  type PlayerInfo,
  fpsManager,
  audioManager
} from '@powerplay/core-minigames'
import {
  AudioGroups,
  AudioNames,
  PlayerTypes,
  type SplitInfo
} from '@/app/types'
import {
  audioGameConfig,
  splitTimesConfig
} from '@/app/config'
import { splitTimeState } from '@/stores'

/**
 * Trieda pre spravu medzicasov
 */
export class SplitTimeManager {

  /** Indexy branok, kde budu medzicasy */
  private splitInfo: SplitInfo[] = []

  /** Pocet medzicasov */
  private splitCount = 0

  /** Aktualny index medzicasu */
  public actualSplitIndex = 0

  /** tween po split time */
  private afterSplitTween: gsap.core.Tween | undefined

  /** maximalny pocet realnych split timov */
  private MAX_SPLIT_COUNT = 1

  /** ci manager patri ovladanemu hracovi */
  public isInstanceOfPlayable = false

  /** uuid playera ktoremu patri splitTime manager */
  public ownerUuid = ''

  /** data aktualneho medzicasu */
  private actualSplitTimeData: PlayerInfo[] = []

  /** Pomocne pole, ktore obsahuje medzicasy, ktore uz super presiel */
  public opponentPassed: boolean[] = [false, false]

  /**
   * getter
   * @returns splitCount
   */
  public getSplitCount(): number {

    return this.splitCount

  }

  /**
   * nastavime splitCount a doplnime splitInfo pokial sme nedostali kompletne data
   */
  public setSplitCount(): void {

    this.splitCount = splitTimesConfig.splitTimePositions.length + 1

    for (let i = 0; i < this.splitCount; i++) {

      if (this.splitInfo[i]?.bestTime) return
      this.splitInfo[i] = {
        time: minigameConfig.dnfValue,
        difference: '',
        splitIndex: i,
        bestTime: minigameConfig.dnfValue
      } as SplitInfo

    }

  }

  /**
   * Skontrolovanie aktualneho medzicasu a jeho zapisanie
   * @param actualTime - Aktualny cas v s
   * @param actualPercent - Aktualna % hodnota prejdenia trate
   * @param lastPercent - Posledna % hodnota prejdenia trate
   * @param splitPercent - % hodnota prejdenia trate na medzicase
   * @param oneMeterInPercent - Jeden meter v % prejdenia trate
   * @param isFinish - True, ak bol cielovy medzicas
   * @param type - Typ hraca na medzicase
   * @returns Final time
   */
  public calculateFinalSeconds(
    actualTime: number,
    actualPercent: number,
    lastPercent: number,
    splitPercent: number,
    oneMeterInPercent: number,
    isFinish = false,
    type: PlayerTypes,
  ): number {

    if (modes.isTutorial() || modes.isTrainingMode() || this.actualSplitIndex >= this.splitCount) return actualTime

    const seconds = actualTime
    const splitLastFrameDist = (actualPercent - lastPercent) / oneMeterInPercent
    const splitDist = (actualPercent - splitPercent) / oneMeterInPercent
    const splitTimeBonusPct = splitDist / splitLastFrameDist
    const splitTimeBonus = splitTimeBonusPct * (1 / fpsManager.fpsLimit)
    const finalSeconds = Math.ceil((seconds - splitTimeBonus) * 100) / 100

    console.log(
      `Medzicas (${type}) ${seconds} => ${finalSeconds} (finish? ${isFinish})`,
      `lastFrameDist ${splitLastFrameDist}`,
      `dist ${splitDist}`,
      `timeBonusPct ${splitTimeBonusPct}`,
      `timeBonus ${splitTimeBonus}`,
      `actualPercent ${actualPercent}`,
      `lastPercent ${lastPercent}`,
      `splitPercent ${splitPercent}`
    )

    return finalSeconds

  }

  /**
   * Zobrazenie best veci (personal best, best time)
   * @param splitDataLength - Velkost split data
   * @param bestTime - najlepsi cas
   */
  private showBestData(splitDataLength: number, bestTime: number): void {

    if (splitDataLength >= 2) {

      this.afterSplitTween = gsap.to({}, {
        onComplete: () => {

          splitTimeState().$patch({
            showSplitTimes: false,
            pb: timeManager.getTimeInFormatFromSeconds(playersManager.getPlayer().personalBest)
          })

        },
        duration: 5
      })

    } else {

      splitTimeState().bestTime = timeManager.getTimeInFormatFromSeconds(bestTime)

    }

  }

  /**
   * Zobrazenie veci v dnenej lige
   * @param changeLastSplitData - Data changeLastSplit
   */
  private showDailyLeagueData(changeLastSplitData: boolean): void {

    if (!modes.isDailyLeague()) return

    this.afterSplitTween = gsap.to({}, {
      onComplete: () => {

        splitTimeState().$patch({
          showSplitTimes: false,
          pb: timeManager.getTimeInFormatFromSeconds(playersManager.getPlayer().personalBest),
        })

        splitTimeState().addSplitTimeData(
          {
            position: '',
            country: '',
            countryString: '',
            player: {
              name: '',
              isPlayer: false
            },
            color: '',
            time: '0',
            timeDiff: ''
          },
          changeLastSplitData
        )

      },
      duration: 5
    })

  }

  /**
   * Nastavenie medzicasovych veci podla typu hraca
   * @param type - Typ hraca
   * @param isFinish - Ci ide o cielovy medzicas
   * @param opponentUuid - UUID supera
   * @param finalSeconds - Finalny cas
   */
  private setSplitDataByType(
    type: PlayerTypes,
    isFinish: boolean,
    opponentUuid: string,
    finalSeconds: number,
  ): void {

    // ak prejde super prvy medzicasom alebo cielom, musime si to poznacit, aby sme ho uz dalej nevylucovali v datach
    // pre medzicas, ked prejde super
    if (type === PlayerTypes.opponent) {

      this.opponentPassed[this.actualSplitIndex] = true

      // musime prepisat medzicasove data
      const opponentData = playersManager.getPlayerById(opponentUuid)
      if (opponentData && !isFinish) opponentData.split = [finalSeconds]

    } else {

      // musime pridat medzicasove data
      const playerData = playersManager.getPlayer()
      if (playerData && !isFinish) playerData.split = [finalSeconds]

    }

  }

  /**
   * Vratenie indexu najlepsieho medzicasu
   * @param opponentUuid - UUID supera
   * @param twoAndLessPlayers - ci su dvaja hraci a menej
   * @param opponentCheckpointsPassed - Pole absolvovanych medzicasov supera
   * @returns Najlepsi medzicas
   */
  private getBestTimeIndex(
    opponentUuid: string,
    twoAndLessPlayers: boolean,
    opponentCheckpointsPassed: boolean[] = []
  ): number {

    const firstSplitData = this.actualSplitTimeData[0]
    const secondSplitData = this.actualSplitTimeData[1]
    const isFirst = (firstSplitData.playable || opponentUuid === firstSplitData.uuid) ? 1 : 0
    const isSecond = secondSplitData.playable || opponentUuid === secondSplitData.uuid
    let bestTimeIndex = isFirst + (isFirst && isSecond ? 1 : 0)

    // musime kontrolovat, kolko je zaznamov, lebo pri 2 a menej to nemusi fungovat spravne
    if (twoAndLessPlayers) {

      // ak super uz presiel medzicasom, tak mozeme brat do uvahy jeho medzicas, inak nie
      bestTimeIndex = opponentCheckpointsPassed.length === 0 || opponentCheckpointsPassed[this.actualSplitIndex] ? 0 : 1

    }
    // pri konecnom medzicase v cieli riesime iba cez nulty index
    if (modes.isDailyLeague() || (twoAndLessPlayers && this.actualSplitIndex === 1)) bestTimeIndex = 0

    return bestTimeIndex

  }

  /**
   * Vratenie player infa
   * @returns Player info
   */
  private getPlayerInfo(): PlayerInfo {

    let playerInfoFull = this.isInstanceOfPlayable ?
      playersManager.getPlayer() :
      playersManager.getPlayerById(this.ownerUuid)

    if (!playerInfoFull) {

      playerInfoFull = {
        country: 'svk',
        countryString: 'svk',
        name: '',
        playable: false,
        uuid: '',
        experiences: 0,
        attribute: {
          total: 0,
          base: 0
        },
        personalBest: 0
      }

    }

    return playerInfoFull

  }

  /**
   * Zobrazenie veci v UI
   * @param diff - Rozdiel
   * @param type - Typ hraca
   * @param finalSeconds - Finalny cas
   * @param isFinish - Ci ide o koniec
   * @param opponentUuid - UUID supera
   * @param bestTime - Najlepsi cas
   * @param twoAndLessPlayers - Ci boli iba dvaja a menej hracov
   * @param opponentCheckpointsPassed - Pole absolvovanych medzicasov supera
   */
  public showInUI(
    diff: number,
    type: PlayerTypes,
    finalSeconds: number,
    isFinish: boolean,
    opponentUuid: string,
    bestTime: number,
    twoAndLessPlayers: boolean,
    opponentCheckpointsPassed: boolean[] = []
  ): void {

    const diffRounded = Math.round((diff) * 100) / 100
    const differencePrefix = SplitTimeManager.getDifferencePrefix(diffRounded)
    const differenceText = SplitTimeManager.formatDifferenceTime(diffRounded, differencePrefix)
    const playerInfo = this.getPlayerInfo()
    const splitData = splitTimeState().splitTimeData
    const changeLastSplitData = type === PlayerTypes.opponent &&
      finalSeconds < Number(splitData[splitData.length - 1]?.time)

    // zaznamenavame medzicas
    if (!isFinish) {

      this.splitInfo[this.actualSplitIndex].time = finalSeconds
      this.splitInfo[this.actualSplitIndex].difference = differenceText

    }

    // ak super v tom istom frame predbehol hraca, tak musime vymenit tych dvoch
    if (splitData[2] && splitData[3] && splitData[2].position === splitData[3].position) {

      splitTimeState().changeSplitTimeData()

    }

    const ownerUUID = this.isInstanceOfPlayable ? playersManager.getPlayer().uuid : this.ownerUuid

    // pole vylucenych casov, moze tam byt max uuid supera, ak este nepresiel medzicasom, alebo ked presiel pred hracom
    const excludeIDs: string[] = []
    if (opponentCheckpointsPassed.length > 0 && !opponentCheckpointsPassed[this.actualSplitIndex]) {

      excludeIDs.push(opponentUuid)

    }

    const players = playersManager.getSplitTimesOfPlayers(this.actualSplitIndex, isFinish).filter((player) => {

      return !excludeIDs.includes(player.uuid)

    })

    const position = players.findIndex((player) => player.uuid === ownerUUID) + 1
    const notDnfOrDns = bestTime !== minigameConfig.dnfValue && bestTime !== minigameConfig.dnsValueAscending

    splitTimeState().addSplitTimeData(
      {
        position: String(position),
        country: playerInfo.country,
        countryString: playerInfo.countryString ?? '',
        player: {
          name: playerInfo.name,
          isPlayer: playerInfo.playable ?? false
        },
        color: SplitTimeManager.getColor(diff),
        time: (Math.round((finalSeconds) * 100) / 100).toString(),
        timeDiff: differenceText,
        showDiff: notDnfOrDns && !(twoAndLessPlayers && position === 1)
      },
      changeLastSplitData
    )

    console.log(
      'show diff ', bestTime !== minigameConfig.dnfValue, !(twoAndLessPlayers && position === 1),
      bestTime, minigameConfig.dnfValue, twoAndLessPlayers, position
    )

    if (!isFinish) {

      splitTimeState().$patch({
        showSplitTimes: true,
        actualTime: finalSeconds.toString(),
        showFinalLap: true
      })

      this.showDailyLeagueData(changeLastSplitData)
      this.showBestData(splitData.length, bestTime)

    } else {

      splitTimeState().addFinalTime(
        timeManager.getTimeInFormatFromSeconds(finalSeconds),
        changeLastSplitData
      )

    }

    // presunieme sa na dalsi medzicas

    this.actualSplitIndex++

  }

  /**
   * Skontrolovanie aktualneho medzicasu a jeho zapisanie
   * @param finalSeconds - Konecny cas
   * @param type - Typ hraca na medzicase
   * @param opponentUuid - Uuid opponenta
   * @param isFinish - True, ak bol cielovy medzicas
   * @param opponentCheckpointsPassed - Pole absolvovanych medzicasov supera
   * @returns Final time
   */
  public checkActualSplit(
    finalSeconds: number,
    type: PlayerTypes,
    opponentUuid: string,
    isFinish = false,
    opponentCheckpointsPassed: boolean[] = [],

  ): void {

    if (modes.isTutorial() || modes.isTrainingMode() || this.actualSplitIndex >= this.splitCount) return

    this.setSplitDataByType(type, isFinish, opponentUuid, finalSeconds)

    this.actualSplitTimeData = playersManager.getSplitTimesOfPlayers(
      this.actualSplitIndex,
      isFinish
    )
    console.warn(this.actualSplitTimeData)
    const twoAndLessPlayers = this.actualSplitTimeData.length <= 2
    const bestTimeIndex = this.getBestTimeIndex(opponentUuid, twoAndLessPlayers, opponentCheckpointsPassed)

    const dataForBestTime = this.actualSplitTimeData.filter((player) => {

      return !modes.isDailyLeague() || player.uuid !== playersManager.getPlayer().uuid

    })

    const bestTime = dataForBestTime[bestTimeIndex]?.finalResult ?? minigameConfig.dnfValue

    splitTimeState().showLeader = !twoAndLessPlayers && bestTime !== minigameConfig.dnfValue

    const difference = finalSeconds - (bestTime ?? finalSeconds)

    // zobrazime v UI
    if (bestTime) {

      this.showInUI(
        difference,
        type,
        finalSeconds,
        isFinish,
        opponentUuid,
        bestTime,
        twoAndLessPlayers,
        opponentCheckpointsPassed
      )

    }

    // pri treningu nechceme zahrat zvuk
    if (!modes.isTrainingMode() && type === PlayerTypes.player && !isFinish) this.playCommentatorSplitTime(difference)

  }

  /**
   * pustime komentatora split time
   */
  private playCommentatorSplitTime(difference: number): void {

    if (
      modes.isTutorial() ||
            modes.isTrainingMode() ||
            audioManager.isAudioGroupPlaying(AudioGroups.commentators)
    ) return

    let audio = AudioNames.commentSplitTimesBad

    if (difference <= 0) {

      audio = AudioNames.commentSplitTimesLead

    } else if (difference <= audioGameConfig.splitTimeDifference) {

      audio = AudioNames.commentSplitTimesClose

    }

    audioManager.play(audio)

  }

  /**
   * Zistenie prefixu pre diff
   * @param difference - Diff
   * @returns Prefix
   */
  public static getDifferencePrefix(difference: number): string {

    let differencePrefix = ''
    if (difference > 0) differencePrefix = '+'
    if (difference < 0) differencePrefix = '-'
    return differencePrefix

  }

  /**
   * Vratenie farby pre medzicasove UI
   * @param difference - Rozdiel casu
   * @returns Farba
   */
  public static getColor(difference: number): string {

    return difference > 0 ? 'red' : 'green'

  }

  /**
   * Naformatovanie diffu casu
   * @param difference - diff
   * @param differencePrefix - prefix pre diff
   * @returns Naformatovany diff time
   */
  public static formatDifferenceTime(difference: number, differencePrefix: string): string {

    const timeInFormat = timeManager.getTimeInFormatFromSeconds(Math.abs(difference))
    return `${differencePrefix}${timeInFormat}`

  }

  /**
   * reset managera
   */
  public reset(): void {

    this.actualSplitIndex = 0
    timeManager.reset()

  }

  /**
   * Vratenie vsetkych checkpointov
   * @returns Pole checkpointov
   */
  public getAllSplitTimes(): number[] {

    const splitTimes = this.splitInfo.slice(0, this.MAX_SPLIT_COUNT)
    return splitTimes.map((value) => value.time ?? 0)

  }

}
