<template>
  <div
    class="time-keeper-laps-container"
    :style="[transform, {transformOrigin: '0 0'}]"
  >
    <div
      :style="{
        width: '383px',
        height: '70px'}"
    >
      <blue-box
        v-if="splitTimeState.showFinalLap"
        side="left"
        :is-scaled="false"
        :style="{
          width: '383px',
          height: '70px',
          display: 'flex',
          'flex-direction': 'row',
          'justify-content': 'flex-start',
          'margin-bottom': '20px',
          background: `linear-gradient(80deg, rgba(24,81,127,0.9) 0%,
        rgba(6,17,35,0.8) 65%, rgba(6,17,35,0.7) 85%, rgba(6,17,35,0) 100%)`
        }"
      >
        <img
          :src="`${pathAssets}/ui/ICO_LAP_TIME.png`"
          style="margin: 0 15px"
          alt="lap"
        >
        <shrinking-text-box
          :text="$t('utFinalLap')"
          :font-size="43"
          :width="300"
          :height="70"
          class="lap-time-text"
        />
      </blue-box>
    </div>
    <record-box
      v-if="splitTimeState.showFinalLap && !splitTimeState.showSplitTimes"
      yellow-text="WR"
      :blue-text="wrFormated"
      style="margin-bottom: 2px"
      :is-scaled="false"
    />
    <record-box
      v-if="splitTimeState.showFinalLap && !splitTimeState.showSplitTimes"
      yellow-text="PB"
      :blue-text="splitTimeState.pb"
      :is-scaled="false"
    />
    <template
      v-if="splitTimeState.showSplitTimes"
    >
      <time-keeper-laps-leader
        v-if="splitTimeState.showLeader"
        :is-scaled="false"
        :text="$t('utLeader')"
        :time="splitTimeState.bestTime"
        style="margin-bottom: 2px"
      />
      <time-keeper-laps
        v-if="players[0]"
        style="margin-bottom: 2px"
        :show-diff="players[0].showDiff"
        :player="players[0]"
        :class="players[0].background"
        :is-scaled="false"
      />
      <time-keeper-laps
        v-if="players[1]"
        :show-diff="players[1].showDiff"
        :player="players[1]"
        :class="players[1].background"
        :is-scaled="false"
      />
    </template>
  </div>
  <div
    v-if="splitTimeState.showFinishSplit"
    class="finish-split"
    :style="[transform, {transformOrigin: '0 100%'}]"
  >
    <time-keeper-laps
      v-if="players[2]"
      style="margin-bottom: 2px"
      :show-diff="players[2].showDiff"
      :player="players[2]"
      :class="players[2].background"
      :is-scaled="false"
    />
    <time-keeper-laps
      v-if="players[3]"
      :show-diff="players[3].showDiff"
      :player="players[3]"
      :class="players[3].background"
      :is-scaled="false"
    />
  </div>
</template>

<script lang="ts">
import {
  TimeKeeperLaps,
  TimeKeeperLapsLeader,
  BlueBox,
  ShrinkingTextBox,
  RecordBox,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { pathAssets } from '@/globals/globalvariables'
import {
  timeManager,
  type SplitTimeData
} from '@powerplay/core-minigames'

import { defineComponent } from 'vue'
import { splitTimeState } from '@/stores'

export default defineComponent({
  name: 'TimeKeeperLapsComponent',
  components: {
    TimeKeeperLaps,
    BlueBox,
    ShrinkingTextBox,
    TimeKeeperLapsLeader,
    RecordBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      pathAssets,
      splitTimeState: splitTimeState(),
    }

  },
  computed: {
    transform() {

      let scaleCoef = this.scaleCoef
      if (this.isWeb) scaleCoef *= 0.8
      return {
        transform: `scale(${scaleCoef})`
      }

    },
    isWeb() {

      return !this.isMobile()

    },
    wrFormated() {

      return timeManager.getTimeInFormatFromSeconds(splitTimeState().wr)

    },
    players() {

      const players = []
      const splitTimeData0: SplitTimeData = splitTimeState().splitTimeData[0]
      if (splitTimeData0) {

        players[0] = {
          name: splitTimeData0.player.name,
          countryString: splitTimeData0.countryString,
          country: splitTimeData0.country,
          position: splitTimeData0.position,
          time: Number(splitTimeData0.time).toFixed(2),
          timeColor: splitTimeData0.player.isPlayer ? 'yellow' : '',
          finalTime: '',
          diff: splitTimeData0.showDiff ? splitTimeData0.timeDiff : '',
          showDiff: splitTimeData0.showDiff,
          diffColor: splitTimeData0.color,
          background: splitTimeData0.player.isPlayer ? 'green' : ''
        }

      }
      const splitTimeData1 = splitTimeState().splitTimeData[1]
      if (splitTimeData1) {

        players[1] = {
          name: splitTimeData1.player.name,
          countryString: splitTimeData1.countryString,
          country: splitTimeData1.country,
          position: splitTimeData1.position === splitTimeData0.position ?
            String(Number(splitTimeData1.position) + 1) :
            splitTimeData1.position,
          time: Number(splitTimeData1.time).toFixed(2),
          timeColor: splitTimeData1.player.isPlayer ? 'yellow' : '',
          finalTime: '',
          diff: splitTimeData1.showDiff ? splitTimeData1.timeDiff : '',
          showDiff: splitTimeData1.showDiff,
          diffColor: splitTimeData1.color,
          background: splitTimeData1.player.isPlayer ? 'green' : ''
        }

      }
      const splitTimeData2 = splitTimeState().splitTimeData[2]
      if (splitTimeData2) {

        const splitData = splitTimeData2
        const player0 = splitTimeData0.player.isPlayer === splitData.player.isPlayer
        const index = player0 ? 0 : 1
        const timeData = splitTimeState().showFinishSplit ?
          Math.round((Number(splitData.time) - Number(splitTimeState().splitTimeData[index].time)) * 100) / 100 :
          Number(splitTimeState().splitTimeData[index].time)

        players[2] = {
          name: splitData.player.name,
          countryString: splitData.countryString,
          country: splitData.country,
          position: splitData.position,
          time: timeData.toFixed(2),
          timeColor: splitData.player.isPlayer ? 'yellow' : '',
          finalTime: splitTimeState().finalTimes[0] || '',
          diff: splitData.showDiff ? splitData.timeDiff : '',
          showDiff: splitData.showDiff,
          diffColor: splitData.color,
          background: splitData.player.isPlayer ? 'green' : ''
        }

      }
      const splitTimeData3 = splitTimeState().splitTimeData[3]
      if (splitTimeData3) {

        const splitData = splitTimeData3
        const player0 = splitTimeData1.player.isPlayer === splitData.player.isPlayer
        const index = player0 ? 1 : 0
        const timeData = splitTimeState().showFinishSplit ?
          Math.round((Number(splitData.time) - Number(splitTimeState().splitTimeData[index].time)) * 100) / 100 :
          Number(splitTimeState().splitTimeData[index].time)

        players[3] = {
          name: splitData.player.name,
          countryString: splitData.countryString,
          country: splitData.country,
          position: splitData.position === splitTimeData2.position ?
            String(Number(splitData.position) + 1) :
            splitData.position,
          time: timeData.toFixed(2),
          timeColor: splitData.player.isPlayer ? 'yellow' : '',
          finalTime: splitTimeState().finalTimes[1] || '',
          diff: splitData.showDiff ? splitData.timeDiff : '',
          showDiff: splitData.showDiff,
          diffColor: splitData.color,
          background: splitData.player.isPlayer ? 'green' : ''
        }

      }

      return players

    }

  }
})

</script>

<style lang="less">
.time-keeper-laps-container {
    position: absolute;
    left: 1%;
    top: 3%;
    display: flex;
    flex-direction: column;

    .lap-time-text {
        font-family: Roboto;
        font-size: 43px;
        font-weight: bold;
        font-style: italic;
        color: #fff;
    }

}
.finish-split {

    position: absolute;
    left: 1%;
    bottom: 3%;
    display: flex;
    flex-direction: column;

}
</style>
