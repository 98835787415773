<template>
  <div
    class="finish-top-box"
    :style="transformCoef"
  >
    <div
      class="finish-top-box-small-white"
    >
      {{ $t('finish') }}
    </div>
    <blue-box
      v-if="showFirstBox"
      :style="{height: 'auto', width: '900px'}"
      :is-scaled="false"
      more-gradient
    >
      <div
        v-if="firstPlace"
        class="finish-top-box-yellow finish-top-box-top-padding"
      >
        {{ $t('congratulations') }}
      </div>
      <div
        v-if="position === 1"
        class="finish-top-box-big-white finish-top-box-full-padding"
      >
        {{ $t('winner') }}
      </div>
      <div
        v-else
        class="finish-top-box-big-white finish-top-box-full-padding"
      >
        {{ $t('rankX').replace('%s', position) }}
      </div>
    </blue-box>
    <blue-box
      v-if="showSecondBox"
      :style="{height: 'auto', width: '900px', 'margin-top': '15px'}"
      :show-border="true"
      :is-scaled="false"
      more-gradient
    >
      <div class="finish-top-box-medium-white finish-top-box-full-padding">
        {{ time }}
      </div>
      <div
        v-if="personalBest"
        class="finish-top-box-yellow finish-top-box-bottom-padding"
      >
        {{ $t('personalBest') }}
      </div>
      <div
        v-if="newPersonalBest"
        class="finish-top-box-yellow finish-top-box-bottom-padding"
      >
        {{ $t('newPersonalBest') }}
      </div>
      <div
        v-if="worldRecord"
        class="finish-top-box-yellow finish-top-box-bottom-padding"
      >
        {{ $t('worldRecord') }}
      </div>
      <div
        v-if="newWorldRecord"
        class="finish-top-box-yellow finish-top-box-bottom-padding"
      >
        {{ $t('newWorldRecord') }}
      </div>
    </blue-box>
  </div>
</template>

<script lang="ts">
import {
  BlueBox,
  WindowAspect
} from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FinishTopBox',
  components: {
    BlueBox
  },
  mixins: [WindowAspect],
  props: {
    showFirstBox: {
      type: Boolean,
      required: false,
      default: false
    },
    showSecondBox: {
      type: Boolean,
      required: false,
      default: false
    },
    firstPlace: {
      type: Boolean,
      required: false,
      default: false
    },
    personalBest: {
      type: Boolean,
      required: false,
      default: false
    },
    newPersonalBest: {
      type: Boolean,
      required: false,
      default: false
    },
    worldRecord: {
      type: Boolean,
      required: false,
      default: false
    },
    newWorldRecord: {
      type: Boolean,
      required: false,
      default: false
    },
    time: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  computed: {
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef})`,
        'transform-origin': 'center top'
      }

    },
  }
})


</script>

<style lang="less" scoped>
    .blue-box {
        height: auto;
        left: 50%;
        top: 5%;
        text-transform: uppercase;
    }
    .finish-top-box-small-white {
        padding: 35px 0 25px;
        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
        font-family: Roboto;
        font-size: 36px;
        font-weight: bold;
        font-style: italic;
        color: #ffffff;
    }

    .finish-top-box-yellow {
        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
        font-family: Roboto;
        font-size: 36px;
        line-height: 36px;
        font-weight: bold;
        font-style: italic;
        color: #fadd1e;
    }

    .finish-top-box-medium-white {

        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
        font-family: Roboto;
        font-size: 48px;
        line-height: 48px;
        font-weight: bold;
        font-style: italic;
        color: #ffffff;
    }

    .finish-top-box-big-white {
        text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
        font-family: Roboto;
        font-size: 60px;
        line-height: 60px;
        font-weight: bold;
        font-style: italic;
        color: #ffffff;
    }

    .finish-top-box-full-padding {
        padding: 20px 0;
    }

    .finish-top-box-top-padding {
        padding: 20px 0 0;
    }

    .finish-top-box-bottom-padding {
        padding: 0 0 20px;
    }
</style>
