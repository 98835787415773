import {
  animationsConfig,
  gameConfig,
  opponentConfig,
  runningPhaseConfig,
  runUpPhaseConfig,
  velocityConfig
} from '@/app/config'
import { materialsConfig } from '@/app/config/materialsConfig'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { RunUpBarManager } from '@/app/SpeedManager/RunUpBarManager'
import { SpeedManager } from '@/app/SpeedManager/SpeedManager'
import {
  CurveType,
  DisciplinePhases,
  MaterialsNames,
  ModelsNames,
  OpponentState,
  PlayerTypes
} from '@/app/types'
import {
  AnimationsManager,
  fpsManager,
  game,
  minigameConfig,
  gsap,
  PlayerSex,
  modes,
  type PlayerInfo,
  playersManager,
  THREE,
  timeManager,
  TimesTypes,
  errorManager,
  MobileDetector
} from '@powerplay/core-minigames'
import { HillLinesCreator } from '../hill/HillLinesCreator'
import { player } from '../player'
import { TriggersManager } from '../triggers/TriggersManager'
import { OpponentAnimationManager } from './OpponentAnimationManager'
import { RunningImpulseManager } from './RunningImpulseManager'

/** Trieda pre spravu protihraca */
export class Opponent {

  /** manager rychlosti */
  public speedManager = new SpeedManager()

  /** 3d objekt protihraca */
  public opponentObject = new THREE.Object3D()

  /** Manager pre animacie */
  public animationsManager!: AnimationsManager

  /** dolezity manager na pohyb */
  public hillLinesManager!: HillLinesCreator

  /** triggers manager */
  public triggersManager = new TriggersManager(PlayerTypes.opponent)

  /** opponent animation manager */
  public opponentAnimationManager = new OpponentAnimationManager()

  /** aktualny stav */
  public actualState = OpponentState.prepare

  /** uuid na ziskavanie dat z playerManagera */
  public uuid = ''

  /** ci uz presiel cielom */
  public finished = false

  /** true ak v danom mode je opponent */
  public isActive = true

  /** vysledny cas */
  public finalTime = minigameConfig.dnfValue

  /** pocet framov do startu */
  private delayedStartFrames = 0

  /** pocet framov do behu */
  private delayedRunning = 0

  /** runUpBarManager */
  private runUpBarManager = new RunUpBarManager()

  /** running impulse manager */
  private runningImpulseManager = new RunningImpulseManager()

  /** pocitadlo framov */
  private frameCounter = 0

  /** pocitadlo framov od freezu */
  private framesFromClick = 0

  private runUpInputCounter = 1

  /**
   * Nastavenie, ci je super aktivny
   */
  public setIsActive(): void {

    this.isActive = !modes.isTutorial() && !modes.isTrainingMode() && !modes.isDailyLeague()

  }

  /**
   * Vytvorenie protihraca
   * @param position - Startovacia pozicia protihraca
   */
  public create(position = gameConfig.startPositionOponent): void {

    if (!this.isActive) return

    console.log('vytvaram proti hraca...')

    this.clonePlayer(position)
    this.hillLinesManager = new HillLinesCreator(
      'Raceline_Opponent',
      'Raceline_Opponent_Finish'
    )
    this.hillLinesManager.setFinishCallback(() => {

      this.finish()

    })
    this.triggersManager.setStartCallback(() => {

      this.curve()

    })
    this.triggersManager.setEndCallback(() => {

      this.flat()
      this.triggersManager.incrementIndex()

    })
    console.log('protihrac vytvoreny...')
    this.resolveOpponentData()

  }

  /**
   * Nastavenie uuid pre supera
   */
  public setUuid(): void {

    if (!this.isActive) return

    const sortedPlayers = playersManager.getPlayersSortedByAttribute()

    const playerIndex = sortedPlayers.findIndex((p: PlayerInfo) => {

      return p.playable

    })
    if ((playerIndex + 1) % 2 === 0) {

      this.uuid = sortedPlayers[playerIndex - 1].uuid

    } else {

      this.uuid = sortedPlayers[playerIndex + 1].uuid

    }

  }

  /**
   * Nastavenie superovho uuid na rozne miesta
   */
  public setOpponentUuid(): void {

    this.triggersManager.splitTimeManager.ownerUuid = this.uuid
    this.triggersManager.opponentUuid = this.uuid
    player.triggersManager.opponentUuid = this.uuid

  }

  /**
   * ziskanie dat pre opponenta
   */
  private resolveOpponentData() {

    const attribute = playersManager.getPlayerById(this.uuid)?.attribute.total
    if (attribute === undefined) return
    this.speedManager.setTopSpeedFromAttribute(attribute)
    this.speedManager.setSpeedAutoIncreaseRunUpFromAttribute(attribute)

  }

  /**
   * naklonovanie hraca
   */
  private clonePlayer(position: THREE.Vector3): void {

    const objectForClone = game.scene.getObjectByName('skier_opponent')
    this.opponentObject = objectForClone ?
      objectForClone :
      game.cloneSkeleton(player.playerObject)

    game.scene.add(this.opponentObject)

    const sexIndex = playersManager.getPlayerById(this.uuid)?.sex === PlayerSex.male ? 0 : 1
    let meshName = materialsConfig[ModelsNames.skier]?.meshesArray?.[sexIndex] ?? ''
    const opponentMesh = this.opponentObject.getObjectByName(meshName) as THREE.Mesh
    if (!opponentMesh) throw new Error(errorManager.showBox('Opponent mesh problem'))
    meshName += 'Opponent'
    opponentMesh.name = meshName

    // musime nastavit material
    opponentMesh.material = game.materialsToUse.basic.get(MaterialsNames.skierOpponent) as THREE.MeshBasicMaterial

    // animacie
    this.animationsManager = new AnimationsManager(
      this.opponentObject,
      animationsConfig,
      game.animations.get(ModelsNames.skier),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()
    this.animationsManager.pauseAll()
    gsap.to({}, {
      onComplete: () => {

        this.animationsManager.unpauseAll()

      },
      duration: opponentConfig.prepareDelay
    })

    // threeJS Section
    this.opponentObject.position.set(
      position.x,
      position.y,
      position.z
    )
    this.opponentObject.name = 'Opponent'

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   */
  public updateBeforePhysics(): void {

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.end) return

    if (!this.isActive) return
    const point = this.hillLinesManager.update(this.speedManager, this.triggersManager)
    this.updatePlayerPosition(point)

    switch (this.actualState) {

      case OpponentState.start:
        this.isStart()
        break

      case OpponentState.runUp:
        this.isRunUp()
        break

      case OpponentState.flat:
        this.isRunning()
        break

      case OpponentState.curve:
        this.isRunning()
        break

      case OpponentState.finish:
        this.isFinish()
        break

      case OpponentState.failedStart:
        this.isFailedStart()
        break

      default:
        break

    }

  }

  /**
   * Pre start
   */
  public preStart(): void {

    if (!this.isActive) return
    this.opponentAnimationManager.startPrestartAnimation()

  }

  /**
   * Start protihraca
   */
  public start(): void {

    if (!this.isActive) return
    this.frameCounter = 0
    const { from, to } = opponentConfig.startFrames
    this.delayedStartFrames = Math.round(Math.random() * (to - from) + from)

    console.log(`Protihracov reakcny cas (frame) = ${this.delayedStartFrames}`)
    this.actualState = OpponentState.start

  }

  /**
   * Start protihraca
   */
  public failedStart(): void {

    if (!this.isActive) return

    this.frameCounter = 0
    const { from, to } = opponentConfig.startFrames
    this.delayedStartFrames = Math.round(Math.random() * (to - from) + from)

    console.log(`Protihracov reakcny cas (frame) = ${this.delayedStartFrames}`)
    this.actualState = OpponentState.failedStart

  }

  /**
   * Rozbeh protihraca
   */
  public runUp(): void {

    if (!this.isActive) return
    this.frameCounter = 0
    this.actualState = OpponentState.runUp

  }

  /**
   * Rovinka protihraca
   */
  public flat(): void {

    if (!this.isActive) return
    this.triggersManager.setNextCurveTriggerType(CurveType.start)
    this.actualState = OpponentState.flat
    this.runningImpulseManager.setIsCurve(false)
    if (!this.opponentAnimationManager.isSkating) {

      this.opponentAnimationManager.isSkating = true
      return

    }
    this.opponentAnimationManager.toggleTurn(false)

  }

  /**
   * Zakruta protihraca
   */
  public curve(): void {

    if (!this.isActive) return
    this.runningImpulseManager.setIsCurve(true)
    this.opponentAnimationManager.toggleTurn(true)
    this.triggersManager.setNextCurveTriggerType(CurveType.end)
    this.actualState = OpponentState.curve

  }

  /**
   * Ciel protihraca
   */
  public finish(): void {

    if (!this.isActive) return

    this.finished = true

    const opponentTime = timeManager.getSecondsFromFrames(timeManager.getFrames(TimesTypes.game), 3)
    playersManager.setPlayerResultsById(
      this.uuid,
      minigameConfig.dnfValue
    )

    this.finalTime = this.triggersManager.splitTimeManager.calculateFinalSeconds(
      opponentTime,
      this.hillLinesManager.getFinishPercent(),
      this.hillLinesManager.getLastPercent(),
      1,
      this.hillLinesManager.oneMeterInPercent,
      false,
      PlayerTypes.opponent,
    )

    playersManager.setPlayerResultsById(
      this.uuid,
      this.finalTime
    )

    this.triggersManager.splitTimeManager.checkActualSplit(
      this.finalTime,
      PlayerTypes.opponent,
      this.uuid,
      true
    )

    // musime dat vysledky zatial naspat, lebo hrac este nepresiel cielom
    if (disciplinePhasesManager.getActualPhase() !== DisciplinePhases.finish) {

      playersManager.setPlayerResultsById(
        this.uuid,
        minigameConfig.dnfValue
      )

    }

    console.log('Finalny cas super', this.finalTime)

    this.opponentAnimationManager.finishAnimation()
    this.actualState = OpponentState.finish

    if (disciplinePhasesManager.getActualPhase() === DisciplinePhases.finish) {

      playersManager.setStandings(2)

    }

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    if (!this.isActive) return
    this.animationsManager.update(delta)
    this.opponentAnimationManager.update()

  }

  /**
   * RemoveCallbacksFromAllAnimations z animations managera osetrene o isActive
   */
  public removeCallbacksFromAllAnimations(): void {

    if (!this.isActive) return
    this.animationsManager.removeCallbacksFromAllAnimations()

  }

  /**
   * start state
   */
  private isStart(): void {

    this.frameCounter += 1

    if (this.delayedStartFrames === this.frameCounter) {

      this.delayedStart()

    }

  }

  /**
   * start state
   */
  private isFailedStart(): void {

    this.frameCounter += 1

    if (this.delayedStartFrames === this.frameCounter) {

      this.delayedFailedStart()

    }

  }

  /**
   * run up state
   */
  private isRunUp(): void {

    this.frameCounter += 1
    this.framesFromClick += 1
    this.speedManager.autoIncreaseRunUp()

    if (this.frameCounter >= fpsManager.fpsLimit * runUpPhaseConfig.runUpDuration) {

      this.flat()
      return

    }
    if (this.framesFromClick <= velocityConfig.speedBar.speedBarInputFreeze) return
    const speedBarFreezeFrames = MobileDetector.isMobile() ?
      velocityConfig.speedBar.mobile.speedBarFreezeFrames :
      velocityConfig.speedBar.desktop.speedBarFreezeFrames

    if (
      this.frameCounter % velocityConfig.speedBar.speedBarAutoDecreaseFrames === 0 &&
      this.framesFromClick >= speedBarFreezeFrames
    ) {

      this.runUpBarManager.removePower(
        this.speedManager,
        velocityConfig.speedBar.speedBarAutoDecreaseValue
      )

    }

    const {
      opponentSpeedBarInputRateMin,
      opponentSpeedBarInputRateMax,
      opponentSpeedBarInputRateFixed,
      opponentSpeedBarRandomFrequency
    } =
        opponentConfig.speedBar

    let inputRate = opponentSpeedBarInputRateFixed
    if (this.runUpInputCounter % opponentSpeedBarRandomFrequency === 0) {

      inputRate = Math.round(Math.random() *
                (opponentSpeedBarInputRateMax - opponentSpeedBarInputRateMin) +
                opponentSpeedBarInputRateMin)

    }
    if (this.frameCounter % inputRate === 0) {

      this.runUpBarManager.addPower(this.speedManager)
      this.framesFromClick = 0
      this.runUpInputCounter += 1

    }

  }

  /**
   * Curve and Flat state
   */
  private isRunning(): void {

    if (this.delayedRunning < runningPhaseConfig.startImpulseDelay) {

      this.delayedRunning += 1
      return

    }

    this.runningImpulseManager.update(this.speedManager)

  }

  /**
   * finish state
   */
  private isFinish(): void {

    this.speedManager.slowDownAfterFinishLine()

  }

  /**
   * daleyed start
   */
  private delayedStart(): void {

    this.frameCounter = 0
    this.runUp()
    this.speedManager.setActive(true)
    this.opponentAnimationManager.startAnimation()

  }

  /**
   * daleyed failed start
   */
  private delayedFailedStart(): void {

    this.speedManager.setActive(true)
    this.opponentAnimationManager.failedStartAnimation()

  }

  /**
   * Aktualizovanie pozicie lyziara
   */
  private updatePlayerPosition(positionObj: THREE.Object3D): void {

    this.opponentObject.position.copy(positionObj.position)

    this.opponentObject.quaternion.slerp(positionObj.quaternion, 1)

    /*
     * this.playerObjectSecond.position.copy(this.playerObject.position)
     * this.playerObjectSecond.quaternion.copy(this.playerObject.quaternion)
     */

  }

  /**
   * reset
   */
  public reset(): void {

    if (!this.isActive) return
    this.speedManager.reset()
    this.hillLinesManager.reset()
    this.actualState = OpponentState.prepare
    this.frameCounter = 0
    this.delayedStartFrames = 0
    this.delayedRunning = 0
    this.runUpBarManager.reset()
    this.opponentAnimationManager.reset()
    this.triggersManager.reset()

  }

}

export const opponent = new Opponent()
