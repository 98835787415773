import {
  type DisciplinePhaseManager,
  TutorialEventType
} from '../../types'
import { ImpulseInputManager } from '@/app/entities/player/ImpulseInputManager'
import { runningPhaseConfig } from '@/app/config'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import {
  gamePhaseState,
  impulseInputState,
  inputsState,
  movementState,
  splitTimeState
} from '@/stores'
import { MobileDetector } from '@powerplay/core-minigames'

/**
 * Trieda fazy pre zjazd
 */
export class RunningPhase implements DisciplinePhaseManager {

  /** Ci je falsed */
  private paused = false

  /** impulseInputManager - managuje spodny impulse bar a stlacenia */
  public impulseInputManager = new ImpulseInputManager()

  /** frame pocitadlo */
  private framesCounter = 0

  /** callback na zavolanie po skonceni fazy */
  private callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Ziskanie stavu pauzed
   * @returns ci je pauzed
   */
  public getPaused(): boolean {

    return this.paused

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    this.impulseInputManager = new ImpulseInputManager()
    tutorialFlow.eventActionTrigger(TutorialEventType.afterRunUp)
    console.warn('running phase started')
    movementState().positionX = 0
    impulseInputState().show = true

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    if (this.framesCounter < runningPhaseConfig.startImpulseDelay) {

      this.framesCounter += 1
      return

    }
    if (this.framesCounter === runningPhaseConfig.startImpulseDelay) {

      tutorialFlow.eventActionTrigger(TutorialEventType.startRunning)
      gamePhaseState().showBar = true
      if (MobileDetector.isMobile()) inputsState().disabled = false
      this.framesCounter += 1

    }

    this.impulseInputManager.update()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    splitTimeState().showFinalLap = false
    impulseInputState().show = false
    console.warn('running phase ended')
    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
